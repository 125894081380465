exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-yachts-index-js": () => import("./../../../src/pages/yachts/index.js" /* webpackChunkName: "component---src-pages-yachts-index-js" */),
  "component---src-pages-yachts-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/yachts/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-yachts-markdown-remark-frontmatter-slug-js" */)
}

